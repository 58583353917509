import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { IMAGE } from './Theme'
import Modal from 'react-bootstrap/Modal';
import { getlocationAction, NotificationAction, sidebarAction } from '../redux/SidebarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosRequest } from '../utils/AxiosRequest';
import ApiConnection, { Credential } from '../utils/ApiConnection';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
const Header = ({data}) => {
    const [isopen, setisopen] = useState(false);
    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
    var width = window.innerWidth < 768;
    const token = localStorage.getItem('ride_access_token');
    const datareducer = useSelector((state) => state.SidebarSlice)
        const [Notification, setNotification] = useState([]);



  console.log(datareducer)

  useEffect(()=>{
    var _loc = JSON.parse(localStorage.getItem("loc"))

    dispatch(getlocationAction(_loc?.loc))
    GetNotification()
    getLocation()

  },[])

  const GetNotification = async ()=>{
 
    try {
        const  response = await ApiConnection.get('user-notification')
        if(response?.status == 200){
            setNotification(response?.data?.data)
            dispatch(NotificationAction(response?.data?.data))
        } else{
          
        }  
    } catch(err){
        
    }
}


    function getLocation() {
        if (navigator.geolocation) {
          navigator.permissions.query({name:'geolocation'}).then(permissionStatus => {
            if (permissionStatus.state === 'denied') {
              alert('Please allow location access.');
              // window.location.href = "app-settings:location";
            } else {
              navigator.geolocation.getCurrentPosition(success, error);
            }
          });
        } else {
          alert('Geolocation is not supported in your browser.');
        }
      }

      const success = async (pos) => {
        var crd = pos.coords;
    console.log(pos)

    getFullAddress(crd)
     
    }
    
    const error = (err) => {
        alert(`ERROR(${err.code}): ${err.message}`);
    }

    const getFullAddress = async coords => {
 
   
      await axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=${Credential.GOOGLE_API_KEY}`,
        )
        .then(async responseJson => {
          let locArr = responseJson?.data?.results?.[0]?.address_components;
          let locc = responseJson?.data?.results?.[0]?.formatted_address;
          var _country = "";
          var _countryCode = "";
          var _city = "";
          var _cityCode = "";
          var _state = "";
          console.log(locArr)
         // dispatch(getlocationAction(loc))
     
        // const formattedAddress = data.results[0]?.formatted_address;

          for (const component of locArr){
            const addressType = component.types[0]
  
            if(addressType.includes('locality')) {
              _city = component.long_name
         
               }
           
            // if(addressType == "administrative_area_level_2"){
            //   _city = component.long_name
            //   _cityCode = component.short_name
            // } 
            if(addressType == "administrative_area_level_3"){
              _state = component.long_name
            }
          
          }

      
  
         let loc = _city + ',' + _state;
         let location = {
          'loc':locc,
          'latitude':coords.latitude,
          'langtude':coords.longitude

         }

         localStorage.setItem("loc", JSON.stringify(location));


         dispatch(getlocationAction(locc))

    
        })
        .catch(err => console.log('cordsss err', err));
    };
 

  return (
    <div className='header'>
        <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-3 col-12'>
                <NavLink to="/">
                    <img src={IMAGE.logo} alt="log" className='logo' />
                 </NavLink>
            </div>
            <div className='col-lg-9 col-12'>
              <div className='header-info'>
                    <ul>
                        <li>
                            <h5><img src={IMAGE.phone_call} alt="hone" /> {data?.header_call_text} <br></br> <b>{data?.phone_number}</b></h5>
                        </li>
                        <li>
                             <div className='header-location'>
                             <h5><img src={IMAGE.globe_light} alt="hone" /> {data?.select_location} </h5>
                             <Dropdown >
                                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{width:datareducer.location != '' ? '360px' : '160px'}}>
                                {datareducer.location != ''  ? datareducer.location  : 'Select Location'}  
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                      <button onClick={getLocation}><i className="fa-solid fa-location-crosshairs"></i> Use my current location</button>
                                </Dropdown.Menu>
                              </Dropdown>  
                            </div>   
                        </li>
                        <li>
                            {token != null ?
                             <NavLink to="/dashboard" className="header_btn">Dashboard</NavLink>
                            :

                              <NavLink to="/login" className="header_btn">Login</NavLink>
                        }
                      
                            {/* <button className="header_btn" onClick={()=>setloginmodal(true)}>
                                Login/Register
                            </button> */}
                        </li>
                        {token != null &&
                        <li className='noti'>
                             <NavLink to="/notification"  className={({ isActive }) => (isActive ? "active" : undefined)}> 
                             <span>
                                <img src={IMAGE.bell} />
                             </span>
                            <label>{Notification?.length}</label>
                             </NavLink>
                        </li>}
                    </ul>
                </div>  
                <div className='menu-container'>
                    <ul className='for_mobile'>
                        <li>
                             <NavLink to="/"  className={({ isActive }) => (isActive ? "active" : undefined)}> 
                             <span>
                                <img src={IMAGE.home} />
                             </span>
                             Welcome
                             </NavLink>
                        </li>
                           {token != null ?
                           <>
                             <li>
                             <NavLink to="/dashboard"  className={({ isActive }) => (isActive ? "active" : undefined)}> 
                             <span>
                                <img src={IMAGE.user} />
                             </span>
                             Dashboard
                             </NavLink>
                        </li>
                        
                        </>
                        :
                        <li>
                             <NavLink to="/login"  className={({ isActive }) => (isActive ? "active" : undefined)}> 
                             <span>
                                <img src={IMAGE.user} />
                             </span>
                             Login/Lets Ride
                             </NavLink>
                        </li>
                        }
                        
                        <li>
                        <button  onClick={() =>{
                        setisopen(!isopen)
                        dispatch(sidebarAction(!sidebarShow)) 
                        }
                             
                                }>
                             <span>
                                <img src={IMAGE.menu} />
                             </span>
                             More
                             </button>
                        </li>
                    </ul>
                    <ul className={sidebarShow ? 'main-menu active' : 'main-menu'} >
                        {!width &&
                        <li>
                            <NavLink to="/"  className={({ isActive }) => (isActive ? "active" : undefined)}>WELCOME</NavLink>
                        </li>
}
{/* <li>
                            <NavLink  to="/about-us" className={({ isActive }) => (isActive ? "active" : undefined)}>Home</NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/book-rides"  className={({ isActive }) => (isActive ? "active" : undefined)}>BOOK TODAY</NavLink>
                        </li>
                         {token != null ?
 <li>
 <NavLink  to="/book-ride"  className={({ isActive }) => (isActive ? "active" : undefined)}>RIDE NOW</NavLink>
</li>
:
<li>
<NavLink to="/login" >RIDE NOW</NavLink>
</li>


                        }
                       
                       
                      
                        <li>
                            <NavLink to="/spintowin"  className={({ isActive }) => (isActive ? "active" : undefined)}>SPIN2WIN</NavLink>
                        </li>
                        <li>
                            <NavLink to="/week-winners"  className={({ isActive }) => (isActive ? "active" : undefined)}>FYI</NavLink>
                        </li>
                        <li>
                            <NavLink to="/faq"  className={({ isActive }) => (isActive ? "active" : undefined)}>FAQ</NavLink>
                        </li>
                       
                    </ul>
                </div>    
            </div>
        </div>
        </div>
        {/* <Modal
        show={loginmodal}
        centered
      >

        <Modal.Body className='text-center py-5'>


        <h5> Please login to book a ride.   <NavLink to="/login">
                    here to Login.
                            </NavLink></h5>
        </Modal.Body>
      
      </Modal> */}
    </div>
  )
}

export default Header