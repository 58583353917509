import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import Index from "./screen/home/Index";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Login from "./screen/auth/Login";
import Register from "./screen/auth/Register";
import About from "./screen/cms/About";
import LiveStreming from "./screen/cms/LiveStreming";
import WeekWiners from "./screen/cms/WeekWiners";
import FaqQuestion from "./screen/cms/FaqQuestion";
import ContactUs from "./screen/cms/ContactUs";
import Dashboard from "./screen/post/Dashboard";
import UpcomingRides from "./screen/post/UpcomingRides";
import CompletedRides from "./screen/post/CompletedRides";
import CancelRide from "./screen/post/CancelRide";
import UpdateProfile from "./screen/post/UpdateProfile";
import ChangePassword from "./screen/post/ChangePassword";
import BookRide from "./screen/post/BookRide";
import NowuKnow from "./screen/cms/NowuKnow";
import { AxiosRequest } from "./utils/AxiosRequest";
import { useEffect } from "react";
import PrivacyPolicy from "./screen/cms/PrivacyPolicy";
import TermsandCondition from "./screen/cms/TermsandCondition";
import ForgotPassword from "./screen/auth/ForgotPassword";
import ResetPassword from "./screen/auth/ResetPassword";
import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";
import NewRide from "./screen/post/NewRide";
import Notification from "./screen/post/Notification";
import SheduleRide from "./screen/post/SheduleRide";

function App() {
  const { sitedata, Getsite} = AxiosRequest()

  useEffect(()=>{
      Getsite()
    },[])

  return (
    <BrowserRouter>
    <Header data={sitedata} />
    <Routes>
      <Route path="/" element={<Index />} />
      <Route element={<PrivateRoute />}> 
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
      </Route>
      <Route element={<PublicRoute />}> 
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/new-rides" element={<NewRide />} />
        <Route path="/upcoming-rides" element={<UpcomingRides />} />
        <Route path="/complete-rides" element={<CompletedRides />} />
        <Route path="/cancel-rides" element={<CancelRide />} />
        <Route path="/update-profile" element={<UpdateProfile />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/change-shedule/:id" element={<SheduleRide />} />
      </Route>
 
      <Route path="/about-us" element={<About />} />
      <Route path="/spintowin" element={<LiveStreming />} />
      <Route path="/week-winners" element={<WeekWiners />} />
      <Route path="/faq" element={<FaqQuestion />} />
      <Route path="/book-rides" element={<NowuKnow />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-condition" element={<TermsandCondition />} />

      <Route path="/book-ride" element={<BookRide />} />
    </Routes>
       <Footer data={sitedata} />
       </BrowserRouter>
  );
}

export default App;
